<template>
  <div class="flex items-center justify-center">
    <p class="max-w-3xl text-center">
      Es posible que tu cotización tenga problemas, o no se haya podido crear una cotización para los datos ingresados.
      Puedes intentar
      <button
        class="underline text-jooycar-blue"
        type="button"
        @click="restartForm()"
      >
        reiniciando el formulario
      </button>
    </p>
  </div>
</template>

<script>
export default {
  methods: {
    restartForm() {
      this.$emit('restart-form');
      this.$store.dispatch('restartProcess');
    },
  },
};
</script>
