<template>
  <button
    type="button"
    v-if="text"
    v-tooltip="{
      content: text,
      trigger: 'hover click',
      autoHide: false,
    }"
  >
    <inline-svg
      :src="require('../assets/images/icons/info.svg')"
      class="text-jooycar-blue-dark"
    />
  </button>
  <v-popover
    v-else
    content="text"
    trigger="hover click"
    placement="top"
    :auto-hide="false"
  >
    <button type="button">
      <inline-svg
        :src="require('../assets/images/icons/info.svg')"
        class="text-jooycar-blue-dark"
      />
    </button>
    <div slot="popover">
      <slot />
    </div>
  </v-popover>
</template>

<script>
import { VTooltip, VPopover } from 'v-tooltip';

export default {
  components: {
    VPopover,
  },
  props: {
    text: { type: String, default: '' },
  },
  directives: {
    'tooltip': VTooltip,
  },
};
</script>

<style lang="scss">
.tooltip {
  z-index: 10000;

  .tooltip-inner {
    @apply text-xs text-justify max-w-sm bg-jooycar-blue text-white rounded-lg px-2 py-1;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    position: absolute;
    margin: 5px;
    z-index: 1;
    @apply border-jooycar-blue;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0;
      border-left-color: transparent;
      border-right-color: transparent;
      bottom: -5px;
      margin-bottom: 0;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}
</style>
