<template>
  <ValidationProvider
    :name="name"
    :rules="rules"
    tag="label"
    class="relative block"
  >
    <template slot-scope="{ errors }">
      <div class="flex">
        <label
          class="block text-sm"
          :for="name"
        >
          {{ label | allCaps }}
        </label>
        <info-tooltip
          class="px-2"
          v-if="tooltipText"
          :text="tooltipText"
        />
      </div>
      <div class="flex items-center w-full leading-tight border rounded bg-jooycar-gray-lightest">
        <span
          v-if="textLeft && variant !== 'small'"
          class="pl-2 pr-1"
        >
          {{ textLeft }}
        </span>
        <input
          v-if="rut"
          :id="name"
          v-rut:live
          class="flex-1 rounded placeholder-jooycar-gray-light focus:outline-none focus:shadow-outline bg-jooycar-gray-lightest"
          :class="paddingClasses"
          :name="name"
          type="text"
          :placeholder="placeholder"
          :disabled="disabled"
          :value="value"
          v-bind="$attrs"
          v-on="listeners"
        >
        <input
          v-else
          :id="name"
          class="flex-1 rounded placeholder-jooycar-gray-light focus:outline-none focus:shadow-outline bg-jooycar-gray-lightest"
          :class="[paddingClasses, disabledClasses]"
          :name="name"
          type="text"
          :placeholder="disabled ? '' : placeholder"
          :disabled="disabled"
          :value="value"
          v-bind="$attrs"
          v-on="listeners"
        >
      </div>
      <div
        class="text-sm text-red-400"
        v-if="errors.length > 0"
      >
        {{ errors[0] }}
      </div>
    </template>
  </ValidationProvider>
</template>

<script>
import { rutFilter } from 'vue-dni';
import InfoTooltip from '../components/info-tooltip';

export default {
  components: {
    InfoTooltip,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    rut: {
      type: Boolean,
      default: () => false,
    },
    rules: {
      type: [String, Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    process: {
      type: Function,
      default: (value) => value,
    },
    variant: {
      type: String,
      default: 'default',
    },
    maxLength: {
      type: Number,
      default: null,
    },
    tooltipText: {
      type: String,
      default: null,
    },
    textLeft: {
      type: String,
      default: null,
    },
  },
  methods: {
    emitValue(value) {
      let processedValue = this.process(value);
      if (this.maxLength) {
        processedValue = processedValue.substring(0, this.maxLength);
      }
      this.$emit('input', processedValue);
    },
  },
  computed: {
    paddingClasses() {
      return {
        'pr-4 py-3': this.variant === 'default' && this.textLeft,
        'px-4 py-3': this.variant === 'default' && !this.textLeft,
        'px-3 py-1': this.variant === 'small',
      };
    },
    disabledClasses() {
      return this.disabled ? 'opacity-25 cursor-not-allowed bg-jooycar-gray-light' : '';
    },
    listeners() {
      return {
        ...this.$listeners,
        input: event => this.emitValue(event.target.value),
      };
    },
  },
  filters: {
    allCaps(value) {
      return value.toUpperCase();
    },
  },
  watch: {
    value() {
      if (this.rut) this.$emit('input', rutFilter(this.value));
    },
  },
};
</script>
