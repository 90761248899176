<template>
  <div class="w-full stepper-padding pt-3 pb-8 bg-jooycar-gray-stepperBg">
    <div class="flex items-center mx-auto">
      <div class="w-5 h-5">
        <div
          class="flex items-center justify-center w-5 h-5 mb-1 rounded-full text-2xs"
          :class="backgroundClass(1)"
        >
          1
        </div>
        <div
          style="font-size: 10px"
          class="-mx-10 text-xs text-center whitespace-no-wrap stepper"
          :class="textClass(1)"
          >
          Datos de cotización
        </div>
        <div
          v-if="this.currentStep == 1"
          style="font-size: 10px"
          class="-mx-10 text-xs text-center whitespace-no-wrap responsive-stepper responsive-first-element"
          :class="textClass(1)"
        >
          Datos de cotización
        </div>
      </div>
      <img
        class="mx-auto"
        alt="loading-car"
        :src="require('../assets/images/icons/blue-arrow.svg')"
      >
      <div class="w-5 h-5 mx-px">
        <div
          class="flex items-center justify-center w-5 h-5 mb-1 rounded-full text-2xs"
          :class="backgroundClass(2)"
        >
          2
        </div>
        <div
          style="font-size: 10px"
          class="-mx-10 text-xs text-center whitespace-no-wrap stepper"
          :class="textClass(2)"
        >
          Cotización
        </div>
         <div
          v-if="this.currentStep == 2"
          style="font-size: 10px"
          class="-mx-10 text-xs text-center whitespace-no-wrap responsive-stepper responsive-first-element"
          :class="textClass(2)"
         >
          Cotización
        </div>
      </div>
      <img
        class="mx-auto"
        alt="loading-car"
        :src="require('../assets/images/icons/blue-arrow.svg')"
      >
      <div class="w-5 h-5 mx-px">
        <div
          class="flex items-center justify-center w-5 h-5 mb-1 rounded-full text-2xs"
          :class="backgroundClass(3)"
        >
          3
        </div>
        <div
          style="font-size: 10px"
          class="-mx-10 text-xs text-center whitespace-no-wrap stepper"
          :class="textClass(3)"
        >
          Ingreso de datos
        </div>
         <div
          v-if="this.currentStep == 3"
          style="font-size: 10px"
          class="-mx-10 text-xs text-center whitespace-no-wrap responsive-stepper responsive-first-element"
          :class="textClass(3)"
         >
          Ingreso de datos
        </div>
      </div>
      <img
        class="mx-auto"
        alt="loading-car"
        :src="require('../assets/images/icons/blue-arrow.svg')"
      >
      <div class="w-5 h-5 mx-px">
        <div
          class="flex items-center justify-center w-5 h-5 mb-1 rounded-full text-2xs"
          :class="backgroundClass(4)"
        >
          4
        </div>
        <div
          style="font-size: 10px"
          class="-mx-10 text-xs text-center whitespace-no-wrap stepper"
          :class="textClass(4)"
        >
          Resumen y pago
        </div>
        <div
          v-if="this.currentStep == 4"
          style="font-size: 10px"
          class="-mx-10 text-xs text-center whitespace-no-wrap responsive-stepper responsive-first-element"
          :class="textClass(4)"
        >
          Resumen y pago
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
  },
  methods: {
    backgroundClass(step) {
      return step < this.currentStep || step === this.currentStep ?
        ('border border-jooycar-blue text-jooycar-blue font-semibold') :
        ('border border-jooycar-gray-stepper text-jooycar-gray-stepper');
    },
    textClass(step) {
      return step < this.currentStep || step === this.currentStep ? 'text-jooycar-blue' : 'text-jooycar-gray-stepper';
    }
  },
};
</script>
<style lang="scss" scoped>
  .responsive-stepper {
      display: none;
  }

  .responsive-first-element {
    position: relative;
    left: 5px;
  }

  .responsive-last-element {
    position: relative;
    right: 5px;
  }

  .stepper-padding {
    padding-left: 20%;
    padding-right: 20%;
  }

  @media (max-width: 565px) {
    .responsive-stepper {
      display: flow-root;
    }

    .stepper {
      display: none;
    }
  }
</style>
