<template>
  <div
    class="progress-bar bg-jooycar-blue-dark"
    :class="`bar-${step}`"
  />
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1,
    }
  },
}
</script>

<style lang="scss" scoped>
  .progress-bar {
    height: 1px;
  }
  .bar-1 {
    width: 30%;
  }
  .bar-2 {
    width: 50%;
  }
  .bar-3 {
    width: 70%;
  }
  .bar-4 {
    width: 100%;
  }
</style>
